<template>
  <div>
    <v-modal-tempales-update
      v-model="dialogUpdate"
      :id="selectedIndex"></v-modal-tempales-update>
    <v-page-title title="Emails"></v-page-title>
    <v-card
      max-width="1200"
      class="mx-auto my-5"
      elevation="0"
      color="transparent">
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-zen
              line-one="Achieve"
              line-two="Wordpress"
              line-three="Zen"></v-zen>
          </v-col>
          <v-col>
            <div class="hounddog-table">
              <v-data-table
                :headers="headers"
                :items="templates"
                :items-per-page="15"
                :sort-desc="true"
                item-key="id">
                <template v-slot:item.edit="{ item }">
                  <v-btn-toggle
                    mandatory
                    background-color="blue-grey lighten-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          color="blue-grey lighten-1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="onClickOpenDialogEmail(item)">
                          <v-icon small color="white"
                            >mdi-text-box-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit Template</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Emails.vue",
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          width: "60px",
        },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
      ],
      dialogUpdate: false,
      selectedIndex: 0,
    };
  },
  async created() {
    await this.$store.dispatch("emailTemplates/all");
  },
  computed: {
    templates() {
      return this.$store.getters["emailTemplates/all"];
    },
  },
  methods: {
    buildScansList() {
      return this.templates;
    },
    onClickOpenDialogEmail(item) {
      this.selectedIndex = item.id;
      this.dialogUpdate = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
